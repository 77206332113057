<template>
  <div class="me-detail-recommend" v-scroll="{
    distance:150,
    onTop:_onTop,
    onBottom:_onBotton
  }">
    <template v-for="(item, index) in list">
      <div
        class="me-detail-recommend__item"
        :key="index"
        @click="handlerToDetail(item.pkId)"
      >
        <Thumb width="110px" height="90px" :src="item.contentPic" />
        <div class="me-detail-recommend__item--info">
          <span
            class="title"
            v-html="
              item.subject.length > 30
                ? item.subject.slice(0, 30)
                : item.subject
            "
          ></span>
          <span
            v-html="
              item.content.length > 54
                ? item.content.slice(0, 54) + '...'
                : item.content
            "
          ></span>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { Base64 } from "js-base64";
import Thumb from "@/components/common/thumb";
import { getRecommends } from "@/api/webApi";
export default {
  name: "Recommend",
  components: {
    Thumb
  },
  data() {
    return {
      list: [],
      page:1,
      pageSize: 10
    };
  },
  props: {
    id: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "service"
    }
  },
  created() {
    this.handlerGetRecommends();
  },
  methods: {
    _onTop(){},
    _onBotton(){
      this.page ++
      this.handlerGetRecommends(this.page)
      
    },
    handlerToDetail(id) {
      id = "pai_" + Base64.encode(id)
      this.$router.push({
        name:
          this.type === "service" || this.type === "enterprise"
            ? "detail"
            : "group",
        params: { id, type: this.type }
      });
    },
    async handlerGetRecommends(page = 1) {
      const params = {
        language: localStorage.getItem("langCode") || "1",
        pageNum: page,
        pageSize: this.pageSize,
        id: this.id
      };
      const result = await getRecommends(
        params,
        `${this.type}/${this.id}`
      ).catch(e => {});
      if (result && result.data){
        const list =  result.data.data;
        this.list = page === 1 ? list : this.list.concat(list)
      }
    }
  }
};
</script>
<style lang="stylus" scoped>
@import '~styles/utils/index';

+b(me-detail-recommend) {
  padding: 0.5rem;
  height 130vh;
  overflow-y scroll

  +e(item) {
    display: flex;
    flex-flow: row;
    margin-bottom: 0.9rem;

    +m(info) {
      display: flex;
      flex-flow: column;
      font-size: 13px;
      color: #666666;
      line-height: 20px;
      text-align: left;
      word-break: break-all;

      .title {
        color: #333333;
        font-size: 15px;
        margin-bottom: 0.25rem;
      }
    }
  }
}
</style>
