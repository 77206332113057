var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "scroll",
          rawName: "v-scroll",
          value: {
            distance: 150,
            onTop: _vm._onTop,
            onBottom: _vm._onBotton
          },
          expression:
            "{\n  distance:150,\n  onTop:_onTop,\n  onBottom:_onBotton\n}"
        }
      ],
      staticClass: "me-detail-recommend"
    },
    [
      _vm._l(_vm.list, function(item, index) {
        return [
          _c(
            "div",
            {
              key: index,
              staticClass: "me-detail-recommend__item",
              on: {
                click: function($event) {
                  return _vm.handlerToDetail(item.pkId)
                }
              }
            },
            [
              _c("Thumb", {
                attrs: { width: "110px", height: "90px", src: item.contentPic }
              }),
              _c("div", { staticClass: "me-detail-recommend__item--info" }, [
                _c("span", {
                  staticClass: "title",
                  domProps: {
                    innerHTML: _vm._s(
                      item.subject.length > 30
                        ? item.subject.slice(0, 30)
                        : item.subject
                    )
                  }
                }),
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(
                      item.content.length > 54
                        ? item.content.slice(0, 54) + "..."
                        : item.content
                    )
                  }
                })
              ])
            ],
            1
          )
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }